<template>
  <el-main style="padding:0px;">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/dashboard' }">图书管理系统</el-breadcrumb-item>
        <el-breadcrumb-item>统计数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card class="box-card" v-loading="class_loading">
            <div slot="header" class="clearfix">
              <span>图书分类情况</span>
            </div>
              <div id="bookClassPieChart" style="width: 100%; height: 470px"></div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card class="box-card" v-loading="top_loading">
            <div slot="header" class="clearfix">
              <span>最热书籍</span>
            </div>
              <div id="topBooksBarChart" style="width: 100%; height: 470px"></div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}


.clearfix {
  font-size: 19px;
}

</style>

<script>
import axios from 'axios'

export default {
  name: "Dashboard",
  data() {
    return {
      class_loading: true,
      top_loading: true,
      bookClassData: [],
      topBooksData: []

    };
  },
  methods: {
    fetchData() {
      axios.get('/userApi/statistics/bookClass', {headers: {'token': localStorage.getItem('ljc_token')}})
          .then(response => {
            if (response.data.code != null && response.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('ljc_token');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (response.data.msg != null && response.data.code != 200) this.$message.error(response.data.msg);

            this.bookClassData = response.data.data;
            this.renderBookClassPieChart();
            this.class_loading = false;
          });

      axios.get('/userApi/statistics/topBooks', {headers: {'token': localStorage.getItem('ljc_token')}})
          .then(response => {
            if (response.data.code != null && response.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('ljc_token');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (response.data.msg != null && response.data.code != 200) this.$message.error(response.data.msg);

            this.topBooksData = response.data.data;
            this.renderTopBooksBarChart();
            this.top_loading = false;
          });


    },
    renderBookClassPieChart() {
      const chart = this.$echarts.init(document.getElementById('bookClassPieChart'));
      const option = {
        title: {
          text: '图书分类情况',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '类别',
            type: 'pie',
            radius: '50%',
            data: this.bookClassData.map(item => ({
              value: item.count,
              name: item.className
            })),
            color: [
              '#50C878',
              '#fb7293',
              '#409EFF',
              '#FF7043',
              '#C678BF'
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      chart.setOption(option);
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    renderTopBooksBarChart() {
      const chart = this.$echarts.init(document.getElementById('topBooksBarChart'));
      const option = {
        title: {
          text: '热门图书',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: this.topBooksData.map(item => item.bookName)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.topBooksData.map(item => item.borrowCount),
            type: 'bar',
            color: [
              '#50C878',
              '#fb7293',
              '#409EFF',
              '#FF7043',
              '#C678BF'
            ]
          },
        ]
      };
      chart.setOption(option);
      window.addEventListener('resize', () => {
        chart.resize()
      })
    }
  },
  mounted() {
    this.fetchData();
  },
  created() {
    this.class_loading = true
    this.top_loading = true
  },
  beforeDestroy() {

  }
};
</script>
